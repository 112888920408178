import React from 'react';
import { ThemeProvider } from 'styled-components';

import { theme } from 'common/theme/appclassic';
import { ResetCSS } from 'common/assets/css/style';
import GlobalStyle from 'containers/AppClassic/appClassic.style';

import RutinaLayout from 'containers/Rutina/Layout';
import RutinaWrapper from 'containers/Rutina/Wrapper';

import SEO from 'components/seo';

export default function () {
  return (
    <ThemeProvider theme={theme}>
      <RutinaLayout locale={'es'} >
        <SEO />
        <ResetCSS />
        <GlobalStyle />

        <RutinaWrapper locale={'es'} />
      </RutinaLayout>
    </ThemeProvider>
  );
}
